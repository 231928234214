import React from "react";
import PropTypes from "prop-types";
import marked from "marked";

const renderer = new marked.Renderer();

class Markdown extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.content !== this.props.content;
  }

  render() {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: marked(this.props.content || "", {
            renderer
          })
        }}
      />
    );
  }
}

Markdown.propTypes = {
  content: PropTypes.string
};

export default Markdown;
